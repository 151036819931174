<script>
  import { ApiError } from '@/api'
  import accountApi from '@/api/account'

  export default {
    data() {
      return {
        formRef: null,
        setPasswordSuccess: false,
        password: null,
      }
    },
    watch: {
      setPasswordSuccess: {
        async handler() {
          setTimeout(() => {
            if (this.setPasswordSuccess) this.$navigate({ name: 'AccountLogin' })
          }, 5000)
        },
      },
    },
    methods: {
      async submit() {
        try {
          const response = await this.formRef.process(
            accountApi.setPassword({
              password: this.password,
              code: this.$route.query.code,
              id: this.$route.query.id,
            })
          )
          if (response.success) {
            this.setPasswordSuccess = true
          }
        } catch (error) {
          if (error instanceof ApiError) {
            // Don't need to do anything with expected (API) errors, so just returning
            return
          }
          return Promise.reject(error)
        }
      },
    },
  }
</script>

<template>
  <section class="py-15">
    <BaseContainer max-width="sm">
      <BaseRichText>
        <BaseHeading size="h1">Choose a new password</BaseHeading>
        <p>
          Passwords must be 8 or more characters, with at least one letter, one number, and one
          special character (@$!%*#?&_)
        </p>
      </BaseRichText>

      <BaseForm
        v-slot="{ inputsEnabled, submitEnabled, errorMessage }"
        v-ref="formRef"
        spinner
        class="mt-10 text-left"
        @submit="submit"
      >
        <p v-if="setPasswordSuccess" class="mb-6 text-primary-dark" data-cy="set-password-success">
          Your password has been reset. Redirecting to login page...
        </p>

        <BasePanelWarning v-if="errorMessage" class="mb-6">
          This password reset link is invalid or expired.
          <BaseLinkStyled :to="{ name: 'AccountResetPassword' }"
            >Request a new password reset link.</BaseLinkStyled
          >
        </BasePanelWarning>

        <BaseStack>
          <BaseInput
            v-model="password"
            type="password"
            name="password"
            password-pattern
            :disabled="!inputsEnabled"
            required
            data-dd-privacy="mask"
          >
            Password
          </BaseInput>
        </BaseStack>

        <BaseButton type="submit" :disabled="!submitEnabled" class="mt-6">
          <slot name="buttonText">Set Password</slot>
        </BaseButton>
      </BaseForm>
    </BaseContainer>
  </section>
</template>
